import { Box, Container, Grid } from "@mui/material";
import CoverSection from "../widgets/CoverSection";
import Seo from "../widgets/Seo";
import Typography from '@mui/material/Typography'
import TextWithPoints from "./sections/AiChat/TextWithPoints";

const AIPage = () => {
    return (
        <div>
            <Seo title="Top OpenAI Developer Malaysia | Excelfixer" desc="Develop solutions using open ai to improve business efficiency."></Seo>
            <CoverSection title="Use AI to improve your efficiency" subtitle="" background="chat-cover.png" category="Ai Chatbot" />


            <Container maxWidth="lg" sx={{ p: 4, py: 4 }}>
                <Box sx={{ background: '#F5F9FB', p: 4 }}>
                    <Grid container spacing={1}>
                        <Typography variant="h1" color="initial">Top 6 Ways AI can assist your Business next year | 2025</Typography>
                        <Grid item xs={6} sx={{ my: 'auto' }}>
                            <TextWithPoints title="1. Customer Service - no surprises here" points={["Answer customer initial enquiries", "Follow up on all sales and prospects"]} />
                            <TextWithPoints title="2. Customer insights for B2C business" points={["Detect customer demography such as gender, race etc", "Group customers based on common buying patterns", "Group customers based on other company specific available metrics"]} />
                            <TextWithPoints title="3. Quality Assurance - Second pair of eyets" points={["AI can very quickly analyse data such as chat history to ensure they adhere to SOPs", "Listen to voice recording and ensure customer service quality"]} />
                            <TextWithPoints title="4. Marketing Optimization - Reach the Right Audience" points={["AI can analyze user behavior and suggest targeted campaigns", "Automate personalized email marketing based on customer activity", "Optimize ad spend by predicting the best-performing platforms"]} />
                            <TextWithPoints title="5. Operational Efficiency - Work Smarter, Not Harder" points={["AI-driven tools can automate repetitive tasks, freeing up staff for higher-value work", "Predictive analytics can help streamline supply chains and inventory management", "AI can assist in task scheduling and resource allocation to improve efficiency"]} />
                            <TextWithPoints title="6. Fraud Detection - Stay One Step Ahead" points={["AI can monitor transactions in real-time to flag suspicious activities", "Predict and identify potential fraud patterns based on historical data", "Machine learning models can improve fraud detection accuracy over time"]} />
                        </Grid>
                        <Grid item xs={6}>
                            <Container sx={{ textAlign: 'center', width: '80%', }}>
                                <img src="/img/lady_with_phone.png" alt="" width={"100%"} />
                            </Container>
                        </Grid>
                    </Grid>
                </Box>
            </Container >
        </div>
    );
}

export default AIPage;