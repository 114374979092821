import { Box, Button, Container, Grid, Typography } from "@mui/material";
import CoverSection from "../../widgets/CoverSection";
import Seo from "../../widgets/Seo";
import { Link } from "react-router-dom";
import { HiddenH1, HiddenH2 } from "../../widgets/HiddenH1";
import React from "react";

const ProductsMain = () => {

    type ProductTileProps = {
        left: boolean,
        title: string,
        desc: string,
        image: string,
        goTo?: string,
    }

    const testi = {
        text: "We needed both a mobile app and a web admin dashboard to manage to our business and Excelfixer got this done efficiently for us.",
        name: "Mr. Wong"
    }

    const ProductTile = ({ left, title, desc, image, goTo }: ProductTileProps) => {

        const ProductTileText = <Box textAlign="center">
            <HiddenH1 text="We build software using ChatGPT AI with database for Southeast Asia" />
            <HiddenH2 text="Develop your own software with low cost and AI based. We develop software for Southeast Asia, Malaysia, Singapore, Australia. Our software development skills uses MySQL & Postgres Database together with Machine Learning and AI-based engineers to build the best software." />
            <Typography variant="h4" color="initial" paragraph>{title}</Typography>
            <Typography variant="body1" color="initial" align="justify">{desc}</Typography>
        </Box>;

        const goToUrl = (e: React.MouseEvent) => {
            if (goTo) {
                e.preventDefault(); // Prevent the default link behavior
                if (goTo.includes('https://')) {
                    window.open(goTo, '_blank'); // Open the link in a new tab
                } else {
                    window.open(goTo);
                }
            }
        }


        return (
            <Link to={goTo ?? ''} onClick={goToUrl} style={{ textDecoration: 'none', color: 'inherit' }}>
                <Grid container spacing={0} sx={{
                    '&:hover': {
                        opacity: 0.5, // Dims the div on hover
                        transition: 'opacity 0.3s ease', // Smooth transition effect
                    },
                }}>
                    {left ? '' : (<Grid item md={6} sm={12} sx={{ alignItems: 'center', display: 'flex', p: 5, justifyContent: 'center', backgroundColor: 'lightgrey' }}>
                        {ProductTileText}
                    </Grid>)}
                    <Grid item md={6} sm={12} sx={{ alignItems: 'center', display: 'flex', }}>
                        <img src={image} alt="" width={"100%"} />
                    </Grid>
                    {!left ? '' : <Grid item md={6} sm={12} sx={{ alignItems: 'center', display: 'flex', p: 5, justifyContent: 'center', backgroundColor: 'lightgrey' }}>
                        {ProductTileText}
                    </Grid>}
                </Grid >
            </Link>
        );
    };



    return (
        <div>
            <Seo title="Best AI Software Provider | ChatGPT | Southeast Asia" desc="Southeast Asia's No.1 software developer. We build softwares using AI (Chat-GPT) and machine learning. We assist client to build leading AI-based software in Southeast Asia."></Seo>
            <CoverSection title='Our Products' subtitle="Our areas of expertise" background="product-background.png" testimonial={testi} coolStatement="INSPIRE.NOW" />
            {/* <CoverSection title="" subtitle="" testimonial="" image="" /> */}
            <Box style={{
                backgroundColor: 'white',
                backgroundSize: "cover",
            }}>
                <Container max-width="lg" sx={{ p: 4, py: 8 }}>
                    <ProductTile left={true} title="Web Design" desc="Design at the edge of your imaginative visuals. We focus on the NEW before it becomes cool. Our team of expert designers will spend the time doing thorough research on the industry standards before coming up with your personalized web design." image="/img/product-web-design.png" />
                    <ProductTile left={false} title="Mobile Apps" desc="Our expert Flutter developers will have your mobile app up and ready in the shortest of time. Will extensive experience from taking projects right from the wireframes state up to being registered on both the App & Play Store, you can be assured your app journey will be a long one with us." image="/img/product-app.png" />
                    <ProductTile left={true} title="E-Commerce Apps" desc="Boost your online business with our expertly crafted E-Commerce app solutions. Our team specializes in creating user-friendly, secure, and scalable apps that deliver a seamless shopping experience. From intuitive product browsing to secure payment processing, we empower you to thrive in the digital marketplace. Let us turn your E-Commerce vision into a reality and take your online store to new heights of success." image="/img/product-e-commerce.png" />
                    <ProductTile left={false} title="AWS Cloud" desc="Looking for a qualified AWS Partner to develop your solution on the most stable cloud platform? We are proud partners of AWS and have years of experiences leveraging on a variety of their cloud solutions." image="/img/product-web-portal.png" />
                    <ProductTile goTo='/products/ai-services' left={true} title="AI Machine Learning" desc="Are you looking to explore & implement AI / Machine Learning solutions? Leverage on our local team and build your own machine learning models with our statistical experts." image="/img/product-api.png" />
                    <ProductTile left={false} title="Google SEO & SEM" desc="Boost your online presence and drive targeted traffic with our comprehensive Google SEO and SEM solutions. Our expert team optimizes your website to rank higher in organic search results, while our strategic ad campaigns and PPC management maximize visibility on search engine pages. Increase brand awareness, attract qualified leads, and achieve measurable results with our tailored Google SEO and SEM strategies. Elevate your digital marketing game and thrive in the competitive online landscape with our proven expertise." image="/img/product-seo.png" />
                    <ProductTile goTo='/products/ai-chat' left={true} title="AI Chat Custom Solutions" desc="Looking to implement ChatGPT-like solutions for your business? Leverage on our AI expertise and utilize advanced tech for your business. We can empower your chatbot with your own knowledge base to ensure the highest accuracy." image="/img/product-chat.png" />
                    <ProductTile goTo='https://www.24hour.ai' left={false} title="24Hour AI For E-Commerce" desc="Plug and play our AI built for Asean languages support for your Shopee, Lazada or Website E-commerce store. You can try the solution for free to see if it fits your use case." image="/img/24hourAi-logo.png" />
                </Container>
            </Box>
        </div>
    );
};

export default ProductsMain;