import React from 'react';
import { Typography, List, ListItem, ListItemText } from '@mui/material';
import CameraIcon from '@mui/icons-material/Camera';

type props = {
    title: String,
    points: String[]
}

const TextWithPointsSection = ({ title, points }: props) => {
    return (
        <>
            <Typography variant="body1" fontWeight={600} color="initial" sx={{ mt: 3 }}>
                {title}
            </Typography>
            <List sx={{ mb: 3 }}>
                {points.map((point, index) => (
                    <ListItem key={index} >
                        <CameraIcon sx={{ pr: 1 }} />
                        <ListItemText secondary={point} />
                    </ListItem>
                ))}
            </List>
        </>
    );
}

export default TextWithPointsSection;
